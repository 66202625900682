import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layouts/Default"
import Header from "../../components/common/Header"
import SvgFeature from "../../components/common/SvgFeature"
import { graphql } from "gatsby"
import Reviews from "../../components/Reviews"
import FeatureSection from "../../components/containers/FeatureSection"
import ContentTwoColumns from "../../components/containers/ContentTwoColumns"
import ContentTextbox from "../../components/common/ContentTextbox"
import CtaSection from "../../components/common/CtaSection"
import illustrationsInvestment from "./../../data/img/illustrations/investment.svg"
import illustrationsGap from "./../../data/img/illustrations/gap.svg"
import H1 from "../../components/typography/H1"
import P from "../../components/typography/P"

export default function Page({ data, location }) {
  const anfrageLink = "/versicherungen/altersvorsorge/anfragen/"

  const svg = {
    shield: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline transition-colors duration-300"
        width="32"
        height="32"
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="var(--primary)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z"></path>
        <path d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3"></path>
      </svg>
    ),
    check: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline transition-colors duration-300"
        width="32"
        height="32"
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="var(--primary)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <rect x="4" y="4" width="16" height="16" rx="2" />
        <path d="M9 12l2 2l4 -4" />
      </svg>
    ),
  }

  return (
    <Layout
      title="Altersvorsorge bei Dierda Finanzdienstleitungen"
      description="Wer sich komplett auf das Rentensystem verlässt, droht in die Altersarmut abzurutschen. Es empfiehlt sich schon heute an morgen zu denken und für das Alter vorzusorgen."
      location={location}
      imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
      imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
      imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
      imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
    >
      <Header
        link={anfrageLink}
        linktext="Jetzt zur Altersvorsorge beraten lassen"
        image={data.header}
        alt="Älteres Paar im Urlaub"
      >
        <div className="text-sm font-semibold tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
          Versicherungen
        </div>
        <H1 className="mt-1">
          Deine sichere Altersvorsorge:{" "}
          <span className="text-blue-500">
            Genieße den Ruhestand in der Sonne
          </span>
        </H1>

        <div className="inline-flex my-8 ">
          <Link to={anfrageLink}>
            <SvgFeature
              svg={svg.shield}
              title="Schließe Deine Altersvorsorge-Lücke"
            />
          </Link>
        </div>

        <P>
          Das umlagefinanzierte deutsche Rentensystem gelangt zunehmend in
          Schieflage. Aufgrund der demographischen Entwicklung werden bereits
          bis 2030 nur noch zwei Beitragszahler einen Rentner finanzieren. Das
          bedeutet für Deine Zukunft: Entweder müssen zukünftig die Beiträge zur
          Rentenversicherung erheblich steigen, was politisch vermutlich schwer
          durchzusetzen sein wird, oder das Rentenniveau wird weiter abgesenkt
          werden müssen. Wer sich komplett auf das Rentensystem verlässt, droht
          deshalb in die Altersarmut abzurutschen. Es empfiehlt sich schon heute
          an morgen zu denken und für das Alter vorzusorgen. Wir nehmen Dich an
          die Hand, damit deine Ruhestandsträume wahr werden.
        </P>
      </Header>

      <FeatureSection title="Was uns besonders macht">
        <SvgFeature
          svg={svg.check}
          title="Ehrlich und fair "
          text="Wir möchten absolut ehrlich, fair und transparent zu Dir sein. Das ist uns wichtig."
        />
        <SvgFeature
          svg={svg.check}
          title="Individuell nach Deinen Bedürfnissen"
          text="Jeder Mensch ist individuell – so sollte er auch behandelt werden."
        />
        <SvgFeature
          svg={svg.check}
          title="Schutz vor Altersarmut und zum Erhalt des Lebensstandards im Alter"
          text="Wir planen mit Dir deinen Ruhestand."
        />
        <SvgFeature
          svg={svg.check}
          title="Ungebunden und unabhängig"
          text="Wir sind an keine Gesellschaft gebunden und planen so ein individuelles Altersvorsorgekonzept auf deine Wünsche zugeschnitten."
        />
        <SvgFeature
          svg={svg.check}
          title="Kostenfreie Beratung"
          text="Wir beraten Dich natürlich kostenlos."
        />
      </FeatureSection>

      <ContentTwoColumns>
        <ContentTextbox title="Verschiebe es nicht auf morgen">
          <p>
            Wer jung ist, hat meist wenig Geld: Erfahrungsgemäß schieben deshalb
            viele das Thema Altersvorsorge Jahr für Jahr vor sich her. Das
            Ergebnis spiegelt sich im Alter wieder: Die Praxis zeigt uns, je
            später Du mit dem Sparen fürs Alter beginnst, desto geringer fällt
            dein Profit vom sogenannten Zinsenszinseffekt aus. Simpel
            ausgedrückt, beschreibt er die Zinsen, die Du als Sparer auf deine
            Zinsen erhältst. Werden diese umgehend reinvestiert, kommt es zu
            einem Zinseszinseffekt. Je länger also der Zeitraum ist, umso höher
            wird Deine private Rente im Alter ausfallen.
          </p>
          <img
            src={illustrationsInvestment}
            alt="Grafik über ein Investement"
            className="w-9/12 mx-auto"
          />
        </ContentTextbox>
        <ContentTextbox title="Klassische Lebensversicherungen und Bausparverträge sind von Gestern">
          <p>
            Heute empfiehlt der Verbraucherschutz zu investmentbasierten
            Produkten. Für viele ist der Kapitalmarkt noch immer ein großes
            Tabuthema, da sie das Risiko scheuen. Wer sein Geld allerdings
            sinnvoll anlegen möchte, egal ob fürs Alter oder mittelfristig,
            kommt um Aktien, Fonds, ETFs, etc. nicht mehr herum. Gerade im
            Bereich der Altersvorsorge ist das Risiko überschaubar, da Du
            langfristig investierst. Ein Rückblick zeigt: Das jahrzehntelange
            Investieren hat sich für den cleveren Sparer immer gelohnt. Ein
            Beispiel: Seit der Einführung vor inzwischen über 30 Jahren, hat der
            deutsche Aktienindex DAX trotz Finanzkrisen seinen Wert um 1200%
            gesteigert.
          </p>
        </ContentTextbox>
      </ContentTwoColumns>

      <CtaSection
        title1="Jetzt zur Altersvorsorge beraten lassen"
        text="Deine sichere Altersvorsorge: Genieße den Ruhestand in der Sonne."
        link1={anfrageLink}
        link1text="Jetzt zur Altersvorsorge beraten lassen"
      />

      <ContentTwoColumns>
        <ContentTextbox title="Unterschätze niemals Deine Lücke">
          <p>
            Inzwischen ist es kein Geheimnis mehr, dass die gesetzliche Rente
            Dir nicht mehr reichen wird um Deinen gewohnten Lebensstandard
            halten zu können. Den Meisten ist es allerdings noch immer unklar
            wie groß die Lücke wirklich sein wird und wieviel für ein
            sorgenfreies Leben im Ruhestand zurückgelegt werden muss. Um eine
            grobe Berechnung zu machen, kannst Du folgende Faustformel anwenden:
            Dein finanzieller Bedarf im Alter minus Deiner Einnahmen aus Deiner
            zukünftigen gesetzlichen Rentenversicherung.
          </p>
          <img
            src={illustrationsGap}
            alt="Grafik über ein schwarzes Loch"
            className="w-6/12 mx-auto"
          />
        </ContentTextbox>
        <ContentTextbox title="Staatliche Förderung in Anspruch nehmen">
          <p>
            Du bist nicht allein: Auch der Staat hat erkannt, dass wir es
            alleine nicht mehr schaffen uns der Altersarmut zu stellen. Somit
            gibt es verschiedene Fördermaßnahmen für Deine private
            Altersvorsorge. Die wohl bekannteste staatliche Förderung ist die
            Riester-Rente. Sie besteht aus drei Teilen:
          </p>
          <ol>
            <li>Grundzulage </li>
            <li>Kinderzulage </li>
            <li>Steuerersparnis </li>
          </ol>
          <p>
            Da diese Beiträge unter die sogenannten “Sonderausgaben” fallen, ist
            es möglich sie bei der Steuer mit anzugeben. Damit Du allerdings die
            volle staatliche Förderung erhältst, ist es wichtig mindestens 4 %
            deines Bruttojahreseinkommens einzuzahlen. Dies gilt bis auf kleine
            Ausnahmen für gesetzlich Versicherte. Für Selbstständige, die nicht
            freiwillig in die gesetzliche Rentenversicherung einzahlen,
            empfiehlt sich z.B. eine Rürup-Rente (auch als Basis-Rente bekannt).
            Diese ist ebenfalls staatlich gefördert und steuerlich absetzbar.{" "}
          </p>
        </ContentTextbox>
      </ContentTwoColumns>
      <ContentTwoColumns>
        <ContentTextbox title="Deine Altersvorsorge ist ein Marathon und kein Sprint  ">
          <p>
            Ein Durchhaltevermögen mit dem richtigen Plan ist das A und O. Wer
            diesen allerdings des öfteren ändert, muss mit Einbußen rechnen, da
            ein Wechsel meist Kosten mit sich bringt. Insbesondere am
            Kapitalmarkt ist es wichtig langfristig zu planen und nicht auf jede
            Marktbewegung zu reagieren. Nicht umsonst heißt es:{" "}
            <strong>“Hin und her macht Taschen leer”</strong>.
          </p>
        </ContentTextbox>
        <ContentTextbox title="Wir nehmen uns bewusst für Dich Zeit">
          <p>
            Wir beraten Dich individuell und entwickeln gemeinsam Deinen Plan,
            der Deiner Lebenssituation entspricht und Deine persönlichen Wünsche
            berücksichtigt.
          </p>
        </ContentTextbox>
      </ContentTwoColumns>
      <Reviews />
    </Layout>
  )
}

export const query = graphql`
  query GeldanlageAltersvorsoge {
    site {
      siteMetadata {
        siteUrl
      }
    }
    header: file(
      relativePath: { eq: "headers/dierda_altersvorsorge_header.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }

    seoImage: file(
      relativePath: { eq: "headers/dierda_altersvorsorge_header.jpg" }
    ) {
      facebook: childImageSharp {
        gatsbyImageData(height: 630, width: 1200)
      }
      twitter: childImageSharp {
        gatsbyImageData(aspectRatio: 1)
      }
    }
  }
`
